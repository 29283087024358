import React from 'react';
import Tag from './Tag';

export default () => {
  return (
    <section className="section" id="skills">
      <h1 className="title is-size-1-desktop is-size-2-tablet is-size-3-mobile has-text-centered has-text-grey-dark" style={{paddingBottom:50,paddingLeft:20, paddingRight:20}}>I have worked with the following</h1>
      <div className="columns is-centered is-mobile">
        <div className='column is-7-desktop is-8-tablet is-10-mobile'>
            <div id="skillsDiv" className="tags are-large" style={{justifyContent:'center'}}>
              <Tag>Python</Tag>
              <Tag>Java</Tag>
              <Tag>Scala</Tag>
              <Tag>React</Tag>
              <Tag>React Native</Tag>
              <Tag>Go</Tag>
              <Tag>Rust</Tag>
              <Tag>C</Tag>
              <Tag>C#</Tag>
              <Tag>Javascript</Tag>
              <Tag>Electron</Tag>
              <Tag>Express</Tag>
              <Tag>.NET</Tag>
              <Tag>WPF</Tag>
              <Tag>AWS</Tag>
              <Tag>Google Cloud</Tag>
              <Tag>MySQL</Tag>
              <Tag>NoSQL</Tag>
              <Tag>Sass</Tag>
              <Tag>PHP</Tag>
              <Tag>Ruby</Tag>
              <Tag>Perl</Tag>
              <Tag>Git</Tag>
              <Tag>Jira</Tag>
              <Tag>Phabricator</Tag>
            </div>
            {/* <div className="box">
              <p>Python</p>
            </div>
            <div className="box">
              <p>Python</p>
            </div> */}
            {/* <div class="columns is-multiline is-mobile is-variable is-1">
              <div class="column is-2">
                <div className="box has-text-centered" style={{padding:8}}>
                  <p style={{fontSize:'calc(1vw + 10px)'}}>Python</p>
                </div>
              </div>
              <div class="column is-2">
                <div className="box has-text-centered" style={{padding:8}}>
                  <p style={{fontSize:'calc(1vw + 10px)'}}>Java</p>
                </div>
              </div>
              <div class="column is-2">
                <div className="box has-text-centered" style={{padding:8}}>
                  <p style={{fontSize:'calc(1vw + 10px)'}}>Scala</p>
                </div>
              </div>
              <div class="column is-2">
                <div className="box has-text-centered" style={{padding:8}}>
                  <p style={{fontSize:'calc(1vw + 10px)'}}>React</p>
                </div>
              </div>
              <div class="column is-3">
                <div className="box has-text-centered" style={{padding:8}}>
                  <p style={{fontSize:'calc(1vw + 10px)'}}>React Native</p>
                </div>
              </div>
              <div class="column is-1">
                <div className="box has-text-centered" style={{padding:8}}>
                  <p style={{fontSize:'calc(1vw + 10px)'}}>C</p>
                </div>
              </div>
              <div class="column is-1">
                <div className="box has-text-centered" style={{padding:8}}>
                  <p style={{fontSize:'calc(1vw + 10px)'}}>Go</p>
                </div>
              </div>
              <div class="column is-2">
                <div className="box has-text-centered" style={{padding:8}}>
                  <p style={{fontSize:'calc(1vw + 10px)'}}>Rust</p>
                </div>
              </div>
              <div class="column is-1">
                <div className="box has-text-centered" style={{padding:8}}>
                  <p style={{fontSize:'calc(1vw + 10px)'}}>Git</p>
                </div>
              </div>
              <div class="column is-2">
                <div className="box has-text-centered" style={{padding:8}}>
                  <p style={{fontSize:'calc(1vw + 10px)'}}>Sass</p>
                </div>
              </div>
              <div class="column is-2">
                <div className="box has-text-centered" style={{padding:8}}>
                  <p style={{fontSize:'calc(1vw + 10px)'}}>Javascript</p>
                </div>
              </div>
              <div class="column is-2">
                <div className="box has-text-centered" style={{padding:8}}>
                  <p style={{fontSize:'calc(1vw + 10px)'}}>Electron</p>
                </div>
              </div>
              <div class="column is-2">
                <div className="box has-text-centered" style={{padding:8}}>
                  <p style={{fontSize:'calc(1vw + 10px)'}}>Express</p>
                </div>
              </div>
              <div class="column is-1">
                <div className="box has-text-centered" style={{padding:8}}>
                  <p style={{fontSize:'calc(1vw + 10px)'}}>C#</p>
                </div>
              </div>
              <div class="column is-2">
                <div className="box has-text-centered" style={{padding:8}}>
                  <p style={{fontSize:'calc(1vw + 10px)'}}>MySQL</p>
                </div>
              </div>
              <div class="column is-2">
                <div className="box has-text-centered" style={{padding:8}}>
                  <p style={{fontSize:'calc(1vw + 10px)'}}>.NET</p>
                </div>
              </div>
              <div class="column is-2">
                <div className="box has-text-centered" style={{padding:8}}>
                  <p style={{fontSize:'calc(1vw + 10px)'}}>WPF</p>
                </div>
              </div>
              <div class="column is-2">
                <div className="box has-text-centered" style={{padding:8}}>
                  <p style={{fontSize:'calc(1vw + 10px)'}}>AWS</p>
                </div>
              </div>
              <div class="column is-3">
                <div className="box has-text-centered" style={{padding:8}}>
                  <p style={{fontSize:'calc(1vw + 10px)'}}>Google Cloud</p>
                </div>
              </div>
              <div class="column is-2">
                <div className="box has-text-centered" style={{padding:8}}>
                  <p style={{fontSize:'calc(1vw + 10px)'}}>NoSQL</p>
                </div>
              </div>
              <div class="column is-2">
                <div className="box has-text-centered" style={{padding:8}}>
                  <p style={{fontSize:'calc(1vw + 10px)'}}>PHP</p>
                </div>
              </div>
              <div class="column is-2">
                <div className="box has-text-centered" style={{padding:8}}>
                  <p style={{fontSize:'calc(1vw + 10px)'}}>Ruby</p>
                </div>
              </div>
              <div class="column is-2">
                <div className="box has-text-centered" style={{padding:8}}>
                  <p style={{fontSize:'calc(1vw + 10px)'}}>Perl</p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
    </section>
  );
}