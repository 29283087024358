import React from 'react';

const intro = "Hello there and welcome! I'm a recent grad in Software Engineering and have professional development experience of about 2 years.";

export default () => {
  return (
    <section className="hero is-fullheight" id="intro">
      <div className="hero-body">
        <div className="container is-fluid">
          <div className="columns is-hidden-mobile is-vcentered">
            <div className="column is-3 is-offset-1">
              <div id="name_banner" className="card">
                <div  className="card-content">
                  <h1 style={{fontSize:'22vw', fontFamily:'Comfortaa'}}>ad</h1>
                </div>
              </div>
            </div>
            <div className="column is-5 is-offset-2">
              <p style={{fontSize:'calc(1.6vw + 15px)'}}>
                {intro}
              </p>
            </div>
          </div>
          <div className="columns is-mobile is-hidden-tablet is-centered" style={{marginBottom:40}}>
            <div className="column is-5">
              <div id="name_banner" className="card">
                <div  className="card-content">
                  <h1 style={{fontSize:'32vw', fontFamily:'Comfortaa'}}>ad</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="columns is-mobile is-hidden-tablet is-centered">
            <div className="column is-9">
              <p className="is-size-4">
                {intro}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
