import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import Resume from './assets/Resume_Online.pdf'

export default () => {
  return (
    <div style={{position:'fixed', top:0, left:0, height:"100vh",  display:'flex', flexDirection:'column', backgroundColor:'transparent', zIndex:100, justifyContent:'space-evenly'}}>
      <Link className="link" style={{color:'black'}} smooth to="/#intro"><p className="has-text-weight-semibold has-text-centered" style={{fontSize:'18px'}}>Intro</p></Link>
      <Link className="link" style={{color:'black'}} smooth to="/#skills" style={{color:'black'}}><p className="has-text-weight-semibold has-text-centered" style={{fontSize:'18px'}}>Skills</p></Link>
      <Link className="link" style={{color:'black'}} smooth to="/#projects" style={{color:'black'}}><p className="has-text-weight-semibold has-text-centered" style={{fontSize:'18px'}}>Projects</p></Link>
      <Link className="link" style={{color:'black'}} smooth to="/#contact" style={{color:'black'}}><p className="has-text-weight-semibold has-text-centered" style={{fontSize:'18px'}}>Contact</p></Link>
      <a href={Resume} target = "_blank" className="link" style={{color:'black'}}><p className="has-text-weight-semibold has-text-centered" style={{fontSize:'18px'}}>Resume</p></a>
    </div>
  );
}