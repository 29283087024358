import React from 'react';
import logo from './logo.svg';
import './App.scss';
import Banner from './Banner';
import Skills from './Skills';
import Projects from './Projects';
import Links from './Links';
import Contact from './Contact';

function App() {
  return (
    <div>
      <Links/>
      <Banner/>
      <Skills/>
      <Projects/>
      <Contact/>
    </div>
  );
}

export default App;
