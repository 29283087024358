import React, {Component} from 'react';
import Rellax from 'rellax';
import Slider from "react-slick";
import Tag from './Tag';

const settings = {
  dots: true,
  arrows: false,
  className: "center",
  centerMode: true,
  infinite: true,
  centerPadding: "100px",
  slidesToShow: 3,
  slidesToScroll: 1,
  speed: 500,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
      }
    },
  ]
};

class SimplyWeather extends Component{

  componentDidMount(){
    this.rellax = new Rellax('.rellax');
  }

  render(){
    return (
      <div style={{backgroundColor:'#EEF3F7'}}>
        <section className="hero is-fullheight" style={{zIndex:1}}>
          <div className="hero-body">
            <div className="container is-fluid">
              <div className="columns is-hidden-mobile is-vcentered">
                <div className="column is-5 is-offset-1">
                  <h1 className="has-text-grey-dark has-text-weight-light" style={{fontSize:'6vw', fontFamily:'Quicksand',lineHeight:'6vw'}}>Simply</h1>
                  <h1 className="has-text-grey-dark has-text-weight-medium" style={{fontSize:'8vw', fontFamily:'Quicksand', lineHeight:'8vw'}}>Weather</h1>
                  <a href='https://play.google.com/store/apps/details?id=com.amandhiman.weather&utm_source=app_website&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                    <img style={{width:"40%"}} alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png'/>
                  </a>
                  <p className="content is-small">
                    *Google Play and the Google Play logo are trademarks of Google LLC.
                  </p>
                </div>
                <div className="column is-6 is-offset-1">
                    <img style={{height:"50%", width:"52%"}} src={require("./assets/SimplyWeather/tor.png")} alt="Placeholder image"/>
                </div>
              </div>
              <div className="columns is-mobile is-hidden-tablet is-centered" style={{marginBottom:40}}>
                <div>
                  <h1 className="has-text-grey-dark has-text-weight-light" style={{fontSize:'8vw', fontFamily:'Quicksand',lineHeight:'8vw'}}>Simply</h1>
                  <h1 className="has-text-grey-dark has-text-weight-medium" style={{fontSize:'11vw', fontFamily:'Quicksand', lineHeight:'11vw'}}>Weather</h1>
                </div>
              </div>
              <div className="columns is-mobile is-hidden-tablet is-centered">
                <div className="column is-12 has-text-centered">
                  <img style={{height:"100%", width:"60%"}} src={require("./assets/SimplyWeather/tor.png")} alt="Placeholder image"/>
                </div>
              </div>
              <div className="columns is-mobile is-hidden-tablet is-centered">
                <div className="column is-6 has-text-centered">
                  <a href='https://play.google.com/store/apps/details?id=com.amandhiman.weather&utm_source=app_website&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                    <img style={{width:"100%"}} alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png'/>
                  </a>
                  <p className="content is-small">
                    *Google Play and the Google Play logo are trademarks of Google LLC.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container is-fluid">
            <div className="columns is-hidden-mobile is-vcentered is-centered">
              <div className="column is-4 has-text-centered">
                <img style={{height:"100%", width:"70%"}} src={require("./assets/SimplyWeather/melb.png")} alt="Placeholder image"/>
              </div>
              <div className="column is-5">
                <h1 className="title is-size-2-desktop is-size-3-tablet is-size-4-mobile has-text-primary">
                  Weather at a glance
                </h1>
                <p className="is-size-4-desktop is-size-5-tablet is-size-6-mobile">
                  Know the weather at a glance with the help of a minimal but informative design.
                </p>
              </div>
            </div>
            <div className="columns is-mobile is-hidden-tablet is-vcentered is-centered">
              <div className="column is-7 has-text-centered">
                <img style={{height:"100%", width:"90%"}} src={require("./assets/SimplyWeather/melb.png")} alt="Placeholder image"/>
              </div>
              <div className="column is-5">
                <h1 className="title is-size-2-desktop is-size-3-tablet is-size-4-mobile has-text-primary">
                  Weather at a glance
                </h1>
                <p className="is-size-4-desktop is-size-5-tablet is-size-6-mobile">
                  Know the weather at a glance with the help of a simplistic but informative design.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="section is-hidden-mobile"></section>
        <section className="section">
          <div className="has-text-centered rellax is-hidden-mobile" data-rellax-speed="2" style={{overflow:'hidden', zIndex:0, height:'60vw'}} >
            <img className="rellax" data-rellax-speed="-5" style={{width:"90vw", position:'relative', top:"calc(-600px - 30vw)"}} src={require("./assets/SimplyWeather/multiLight.png")} alt="Placeholder image"/>
          </div>
          <div className="has-text-centered rellax is-hidden-tablet" data-rellax-speed="0" style={{overflow:'hidden', zIndex:0, height:'40vw', width:'100vw'}} >
            <img className="rellax" data-rellax-speed="-5" style={{width:"110vw", position:'relative', top:"calc(-700px - 30vw)", left:'-5vw'}} src={require("./assets/SimplyWeather/multiLight.png")} alt="Placeholder image"/>
          </div>
        </section>
        <section className="section is-hidden-tablet"></section>
        <section className="section is-hidden-tablet"></section>
        <section className="section" style={{position:'relative', top:'-180px'}}>
          <div className="columns is-centered">
            <div className="column is-3">
              <h1 className="title is-size-2-desktop is-size-3-tablet is-size-4-mobile has-text-primary">
                Features
              </h1>
            </div>
            <div className="column is-3">
              <h1 className="title is-size-4-desktop is-size-5-tablet is-size-5-mobile">
                Simple, Minimalistic, Elegant UI
              </h1>
              <p className="content is-medium has-text-grey">
                It is designed to be simple enough to know the weather at a glance and comes with a soothing elegant UI.
              </p>
            </div>
            <div className="column is-3">
              <h1 className="title is-size-4-desktop is-size-5-tablet is-size-5-mobile">
                Accurate hyperlocal weather forecasts
              </h1>
              <p className="content is-medium has-text-grey">
                Being Powered by <a className="has-text-primary" href="https://darksky.net/poweredby/" target="_blank">Dark Sky</a>,
                it provides accurate hyperlocal forecasts.
              </p>
            </div>
          </div>
          <div className="columns is-centered">
            <div className="column is-3">
              <h1 className="title is-size-4-desktop is-size-5-tablet is-size-5-mobile">
                Charts and Images for conciseness
              </h1>
              <p className="content is-medium has-text-grey">
                The graphical representation of the data allows to deduce the overall condition quickly and with ease.
              </p>
            </div>
            <div className="column is-3">
              <h1 className="title is-size-4-desktop is-size-5-tablet is-size-5-mobile">
                Pull to refresh
              </h1>
              <p className="content is-medium has-text-grey">
                Pull the view from the top to refresh data for the current city.
              </p>
            </div>
            <div className="column is-3">
              <h1 className="title is-size-4-desktop is-size-5-tablet is-size-5-mobile">
                Tap on the hour or day for more details
              </h1>
              <p className="content is-medium has-text-grey">
                To see more details for a certain time period (hour or day), just tap on that time and a screen will pop up displaying those details.
              </p>
            </div>
          </div>
          <div className="columns is-centered">
            <div className="column is-3">
              <h1 className="title is-size-4-desktop is-size-5-tablet is-size-5-mobile">
                Severe Weather Alerts
              </h1>
              <p className="content is-medium has-text-grey">
                Whenever there's a severe weather alert for any of the locations present in the app, a pop up will display all the details about it.
              </p>
            </div>
            <div className="column is-3">
              <h1 className="title is-size-4-desktop is-size-5-tablet is-size-5-mobile">
                Individual Units
              </h1>
              <p className="content is-medium has-text-grey">
                The setting for each unit can be changed individually. For example, Speed unit can be switched between mi/h and km/h.
              </p>
            </div>
            <div className="column is-3">
              <h1 className="title is-size-4-desktop is-size-5-tablet is-size-5-mobile">
                Dark mode and Accent colors
              </h1>
              <p className="content is-medium has-text-grey">
                Using your phone from a cave? Use dark mode to reduce the strain on your eyes. Switch between accent colors to your liking.
              </p>
            </div>
          </div>
          <div className="columns is-centered" style={{marginTop: 50}}>
            <div className="column is-8">
              <img src={require("./assets/SimplyWeather/multiDark.png")} alt="Placeholder image"/>
            </div>
          </div>
        </section>
        <section className="section" style={{position:'relative', top:'-100px'}}>
          <div className="columns is-centered is-mobile">
            <div className='column is-9-desktop is-9-tablet is-12-mobile'>
            <h1 className="title is-size-2-desktop is-size-3-tablet is-size-4-mobile has-text-primary">
            Data displayed
          </h1>
              <div className="content is-medium">
                <ul className="">
                  <li style={{display:'inline-block', margin:"0 15px"}}>&bull; Temperature</li>
                  <li style={{display:'inline-block', margin:"0 15px"}}>&bull; Condition</li>
                  <li style={{display:'inline-block', margin:"0 15px"}}>&bull; Feel Like Temperature</li>
                  <li style={{display:'inline-block', margin:"0 15px"}}>&bull; Temperature High/Low</li>
                  <li style={{display:'inline-block', margin:"0 15px"}}>&bull; Sunrise/Sunset</li>
                  <li style={{display:'inline-block', margin:"0 15px"}}>&bull; Summary</li>
                  <li style={{display:'inline-block', margin:"0 15px"}}>&bull; Precipitation Chance</li>
                  <li style={{display:'inline-block', margin:"0 15px"}}>&bull; Precipitation Intensity</li>
                  <li style={{display:'inline-block', margin:"0 15px"}}>&bull; Wind Speed</li>
                  <li style={{display:'inline-block', margin:"0 15px"}}>&bull; Wind Direction</li>
                  <li style={{display:'inline-block', margin:"0 15px"}}>&bull; Humidity</li>
                  <li style={{display:'inline-block', margin:"0 15px"}}>&bull; Pressure</li>
                  <li style={{display:'inline-block', margin:"0 15px"}}>&bull; Dew Point</li>
                  <li style={{display:'inline-block', margin:"0 15px"}}>&bull; UV Index</li>
                  <li style={{display:'inline-block', margin:"0 15px"}}>&bull; Visibility</li>
                  <li style={{display:'inline-block', margin:"0 15px"}}>&bull; Cloud Cover</li>
                  <li style={{display:'inline-block', margin:"0 15px"}}>&bull; Ozone</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="section" style={{position:'relative', top:'-50px', margin:0, padding:0}}>
          <div className="container is-fluid" style={{margin:0}}>
            <Slider {...settings}>
              <div style={{width:'210px'}}>
                <img style={{width:"210px"}} src={require("./assets/SimplyWeather/splash.png")} alt="Placeholder image"/>
              </div>
              <div className="has-text-centered" style={{width:'210px'}}>
                <img style={{width:"210px"}} src={require("./assets/SimplyWeather/alerts.png")} alt="Placeholder image"/>
              </div>
              <div className="has-text-centered" style={{width:'210px'}}>
                <img style={{width:"210px"}} src={require("./assets/SimplyWeather/hourlyDetails.png")} alt="Placeholder image"/>
              </div>
              <div className="has-text-centered" style={{width:'210px'}}>
                <img style={{width:"210px"}} src={require("./assets/SimplyWeather/dailyDetails.png")} alt="Placeholder image"/>
              </div>
            </Slider>
          </div>
        </section>
      </div>
    );
  }
}

export default SimplyWeather;