import React from 'react';
import {Link} from 'react-router-dom';

export default () => {
  return (
    <section className="section" id="projects">
      <h1 className="title is-size-1-desktop is-size-2-tablet is-size-3-mobile has-text-centered has-text-grey-dark" style={{paddingBottom:50}}>Projects</h1>
      <div className="columns is-centered is-mobile is-multiline">
        <div className='column is-3-desktop is-5-tablet is-7-mobile'>
          <Link to="/simplyweather" target="_blank">
            <div id="simplyWeather" class="card">
              <div class="card-image">
                <figure class="image is-3by2">
                  <img src={require("./assets/SimplyWeatherBanner.png")} alt="Placeholder image"/>
                </figure>
              </div>
              <div class="card-content">
                <div class="media">
                  <div class="media-left">
                    <figure class="image is-48x48">
                      <img src={require("./assets/SimplyWeatherIcon.png")} alt="Placeholder image"/>
                    </figure>
                  </div>
                  <div class="media-content">
                    <p class="title is-4">Simply Weather</p>
                  </div>
                </div>

                <div class="content">
                  A minimalistic weather app that doesn't overwhelm but provides all the information you need.
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </section>
  );
}