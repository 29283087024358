import React from 'react';

const getEmail = (dom, user2, user1) => {
  return user1 + user2 + "@" + dom + ".com";
}

export default () => {
  return (
    <section className="section" id="contact">
      <h1 className="title is-size-1-desktop is-size-2-tablet is-size-3-mobile has-text-centered has-text-grey-dark" style={{paddingBottom:50}}>Get in touch</h1>
      <div className="columns is-centered is-mobile">
        <div className='column is-4-desktop is-6-tablet is-8-mobile'>
          <nav className="level is-mobile">
            <div className="level-item has-text-centered">
              <a href={`mailto:${getEmail("gmail","dhiman","amans")}`} target="_blank" style={{color:'hsl(0, 0%, 48%)'}}>
                <span className="icon is-medium" style={{zIndex:2}}>
                  <i className="fas fa-2x fa-envelope"></i>
                </span>
              </a>
            </div>
            <div className="level-item has-text-centered">
              <a href="https://www.github.com/aman-dhiman" target="_blank" style={{color:'hsl(0, 0%, 48%)'}}>
                <span className="icon is-medium" style={{zIndex:2}}>
                  <i className="fab fa-2x fa-github-alt"></i>
                </span>
              </a>
            </div>
            <div className="level-item has-text-centered">
              <a href="https://www.linkedin.com/in/amandhiman" target="_blank" style={{color:'hsl(0, 0%, 48%)'}}>
                <span className="icon is-medium" style={{zIndex:2}}>
                  <i className="fab fa-2x fa-linkedin-in"></i>
                </span>
              </a>
            </div>
          </nav>
          {/* <nav className="level is-mobile" id="contactDiv" style={{height:8, position:'relative', top:-10}}></nav> */}
        </div>
      </div>
    </section>
  );
}